<template>
  <div>
    <v-col cols="12" center>
      <v-row>
        <div v-for="(images, index) in imageData" :key="index" class="pa-2">
          <v-img
            v-if="images.questionType == 'imageUrlQuestion'"
            height="300px"
            width="300px"
            :src="images.answer"
            @click="zoom(images.answer)"
          />
          <v-dialog v-model="selectedImage" max-width="750px">
            <v-img
              v-if="images.questionType == 'imageUrlQuestion'"
              height="750px"
              width="750px"
              :src="selectedUrl"
            />
          </v-dialog>
        </div>
      </v-row>
    </v-col>
    <div class="mt-10">
      <v-card>
        <v-card-title>Select status for the submission</v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4">
              <v-select v-model="selectedStatus" :items="status" label="Select Status"></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>

    <div v-if="selectedStatus == 3" class="mt-10">
      <v-card class="mt-10">
        <v-card-title>Add Points to the submission</v-card-title>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  v-model="share"
                  label="No of Shares"
                  persistent-hint
                  hint="Each Share is 25 points"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  v-model="likes"
                  label="No of Likes"
                  persistent-hint
                  hint="Each Like is 1 points"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  v-model="comments"
                  label="No of Comments"
                  persistent-hint
                  hint="Each Comment is 1 points"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <h2 class="ml-5 mb-5">Total Points: {{result}}</h2>
        <v-spacer></v-spacer>
        <v-btn class="mb-5 ml-5"  @click="clear()" color="primary">Clear Points</v-btn>
      </v-card>
    </div>

    <div v-if="selectedStatus == 4" class="mt-10">
      <v-card>
        <v-card-title>Email to the user</v-card-title>
        <v-container>
          <v-text-field
            v-model="emailData.subject"
            label="Subject"
            single-line
            :rules="[v => !!v || 'Subject is required']"
          ></v-text-field>
          <quill-editor
            ref="myQuillEditor"
            v-model="emailData.body"
            :rules="[v => !!v && v.length > 0 || 'Body is required']"
          />
        </v-container>
      </v-card>
    </div>
    <div class="mt-10">
      <v-btn v-if="selectedStatus == 2 || selectedStatus == 3 " large @click="confirmSelection()" class="mr-5" color="primary">Update</v-btn>
      <v-btn v-if="selectedStatus == 4" large @click="sendEmail()" class="mr-5" color="primary">Send Email</v-btn>
    </div>
    <v-dialog max-width="500px" v-model="confirmUpdate">
      <v-card>
        <v-card-title class="headline">Confirm Points</v-card-title>
        <v-card-text>
          <p v-if="selectedStatus == 3">
            Are you sure you want to add
            <strong>{{result}} points</strong> to the user?
          </p>
          <p v-if="selectedStatus == 2">
            Are you sure you want to reject the submission?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmUpdate = false">No</v-btn>
          <v-btn color="primary" @click="updateUserSharing()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import RestResource from "../../services/dataServiceMobile";
const service = new RestResource();
export default {
  data() {
    return {
      emailData: {
        subject: null,
        body: null,
        emailId: "",
        name: ""
      },
      // emailId: "",
      selectedStatus: "",
      imageData: [],
      share: "",
      likes: "",
      comments: "",
      selectedImage: false,
      confirmUpdate: false,
      selectedUrl: "",
      totalPoints: 0,
      status: [
        {
          text: "REJECT",
          value: 2
        },
        {
          text: "APPROVE",
          value: 3
        },
        {
          text: "NEED MORE INFO",
          value: 4
        }
      ]
    };
  },

  mounted() {
    this.getImageData();
    this.findEmailById()
  },

  methods: {
    getImageData() {
      this.$setLoader()
      service
        .findImageDataByUserId({ _id: this.$route.params.sharingId })
        .then(res => {
          this.imageData = res.data.data;
          this.$disableLoader()
        });
    },

    clear() {
     this.share = "",
      this.likes =  "",
      this.comments = ""
    },

    async updateUserSharing() {
      this.result = ""
      let data = {
        _id: this.$route.params.sharingId,
        points: this.result,
        status: this.selectedStatus,
        userId: this.$route.params.userId
      };
      this.$setLoader()
      await service.updateSocialSharing(data).then(res => {
      this.confirmUpdate = false
       this.$showSuccessMessage(res.data)
      });
    },

    async findEmailById() {
      await service.findEmailById({ userId: this.$route.params.userId}).then(res => {
        this.emailData.emailId = res.data.email
        this.emailData.name = res.data.name
      })
    },

    zoom(url) {
      this.selectedImage = true;
      this.selectedUrl = url;
    },

    confirmSelection() {
      this.confirmUpdate = true;
    },
    
    sendEmail() {
      let data = {
          _id: this.$route.params.sharingId,
          status: this.selectedStatus,
          emailData: this.emailData
      }
      this.$setLoader()
      service.sendEmailSocialSharing(data).then(res => {
        this.$showSuccessMessage(res.data)
      })
    }
  },
  computed: {
    result() {
      return (
        // parseInt(this.share == undefined || this.share == 0 ? 0 : this.share) +
        // parseInt(this.likes == undefined || this.likes == 0 ? 0 : this.likes) +
        // parseInt(this.comments == undefined || this.comments == 0 ? 0 : this.comments)
        Number(this.share * 25) + Number(this.likes) + Number(this.comments)
      );
    }
  }
};
</script>

<style scoped>
p {
  font-size: 20px;
}
</style>